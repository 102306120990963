import cookieParser from 'cookieparser'
import { getToken, setToken } from '~/common/utils/auth'
import { getInfo } from '~/common/api/auth'
import { getBanner } from '~/common/api/banner'
// import Resource from '~/common/api/resource'
// const numberNotifyResource = new Resource('notify/get-number-notify')

export const state = () => ({
  authenticated: false,
  user: null,
  token: getToken(),
  socketMessageSideBar: '',
  isVerifiedMobile: 0,
  isMobile: false,
  userQuestionTab: 0,
  numberNewNotify: 0,
  classFilter: null,
  statusFilter: 0,
  scrollMenuMobile: 0,
  banner: [],
  showQuestionDialog: false,
  imageSearch: null,
  storeRedirect: null,
  storeFlag: null,
  viettel: ''
})
export const mutations = {
  SET_AUTHENTICATED: (state, authenticated) => {
    state.authenticated = authenticated
  },
  SET_TOKEN: (state, token) => {
    state.token = token
    setToken(token)
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_USER_AVATAR: (state, avatar) => {
    state.user.avatar = avatar
  },
  SET_USER_FULLNAME: (state, fullName) => {
    state.user.fullName = fullName
  },
  SET_SOCKET_MESSAGE_SIDE_BAR: (state, socketMessageSideBar) => {
    state.socketMessageSideBar = socketMessageSideBar
  },
  SET_VERIFIED_MOBILE: (state, isVerifiedMobile) => {
    state.isVerifiedMobile = isVerifiedMobile
  },
  SET_USER_MOBILE: (state, mobile) => {
    state.user.mobile = mobile
  },
  SET_USER_EMAIL: (state, email) => {
    state.user.email = email
  },
  SET_IS_MOBILE: (state, isMobile) => {
    state.isMobile = isMobile
  },
  SET_USER_QUESTION_TAB: (state, userQuestionTab) => {
    state.userQuestionTab = userQuestionTab
  },
  SET_NUMBER_NEW_NOTIFY: (state, numberNewNotify) => {
    state.numberNewNotify = numberNewNotify
  },
  SET_CLASS_FILTER: (state, classFilter) => {
    state.classFilter = classFilter
  },
  SET_STATUS_FILTER: (state, statusFilter) => {
    state.statusFilter = statusFilter
  },
  SET_SCROLL_MENU_MOBILE: (state, scrollMenuMobile) => {
    state.scrollMenuMobile = scrollMenuMobile
  },
  SET_BANNER: (state, banner) => {
    state.banner = banner
  },
  SET_SHOW_QUESTION_DIALOG: (state, showQuestionDialog) => {
    state.showQuestionDialog = showQuestionDialog
  },
  SET_IMAGE_SEARCH: (state, image) => {
    state.imageSearch = image
  },
  SET_STORE_REDIRECT: (state, data) => {
    state.storeRedirect = data
  },
  SET_STORE_FLAG: (state, data) => {
    state.storeFlag = data
  },
  SOCKET_receiveNotification (state, data) {
    state.numberNewNotify++
  },
  SET_VIETTEL (state, viettel) {
    state.viettel = viettel
  }
}

export const actions = {
  nuxtServerInit ({ commit }, { req }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const banner = await getBanner().catch(() => {})
      if (banner && banner.status === 200 && banner.data) {
        commit('SET_BANNER', banner.data)
      }
      if (req.headers.cookie) {
        const requestCookies = cookieParser.parse(req.headers.cookie)
        const token = requestCookies.access_token || ''
        if (token) {
          const user = await getInfo(token).catch(() => {
            return resolve()
          })
          if (user && user.status === 200 && user.data) {
            commit('SET_AUTHENTICATED', true)
            commit('SET_VERIFIED_MOBILE', user.data.mobileVerify)
            commit('SET_USER', {
              email: user.data.email,
              fullName: user.data.fullName,
              userName: user.data.userName,
              avatar: user.data.avatar,
              uuid: user.data.uuid,
              mobile: user.data.mobile
            })
            // const query = {
            //   userId: user.data.uuid
            // }
            // const { data, status } = await numberNotifyResource.list(query, token)
            // if (status === 200) {
            //   commit('SET_NUMBER_NEW_NOTIFY', data.countNotiNotRead)
            // }
          }
          return resolve()
        } else {
          return resolve()
        }
      } else {
        return resolve()
      }
    })
  },
  socketConnect ({ commit }) {

  },
  setVerifiedMobile ({ commit }, isVerifiedMobile) {
    return new Promise((resolve) => {
      commit('SET_VERIFIED_MOBILE', isVerifiedMobile)
      resolve()
    })
  },
  setUserMobile ({ commit }, mobile) {
    return new Promise((resolve) => {
      commit('SET_USER_MOBILE', mobile)
      resolve()
    })
  },
  setUserEmail ({ commit }, email) {
    return new Promise((resolve) => {
      commit('SET_USER_EMAIL', email)
      resolve()
    })
  },
  setUserFullName ({ commit }, fullName) {
    return new Promise((resolve) => {
      commit('SET_USER_FULLNAME', fullName)
      resolve()
    })
  },
  setIsMobile ({ commit }, isMobile) {
    return new Promise((resolve) => {
      commit('SET_IS_MOBILE', isMobile)
      resolve()
    })
  },
  setUserQuestionTab ({ commit }, userQuestionTab) {
    return new Promise((resolve) => {
      commit('SET_USER_QUESTION_TAB', userQuestionTab)
      resolve()
    })
  },
  setNumberNewNotify ({ commit }, numberNewNotify) {
    return new Promise((resolve) => {
      commit('SET_NUMBER_NEW_NOTIFY', numberNewNotify)
      resolve()
    })
  },
  setClassFilter ({ commit }, classFilter) {
    return new Promise((resolve) => {
      commit('SET_CLASS_FILTER', classFilter)
      resolve()
    })
  },
  setStatusFilter ({ commit }, statusFilter) {
    return new Promise((resolve) => {
      commit('SET_STATUS_FILTER', statusFilter)
      resolve()
    })
  },
  setScrollMenuMobile ({ commit }, scrollMenuMobile) {
    return new Promise((resolve) => {
      commit('SET_SCROLL_MENU_MOBILE', scrollMenuMobile)
      resolve()
    })
  },
  setShowQuestionDialog ({ commit }, showQuestionDialog) {
    return new Promise((resolve) => {
      commit('SET_SHOW_QUESTION_DIALOG', showQuestionDialog)
      resolve()
    })
  },
  setImageSearch ({ commit }, image) {
    return new Promise((resolve) => {
      commit('SET_IMAGE_SEARCH', image)
      resolve()
    })
  },
  setStoreRedirect ({ commit }, data) {
    return new Promise((resolve) => {
      commit('SET_STORE_REDIRECT', data)
      resolve()
    })
  },
  setStoreFlag ({ commit }, data) {
    return new Promise((resolve) => {
      commit('SET_STORE_FLAG', data)
      resolve()
    })
  }
}
